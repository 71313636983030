import { CODE_SET_DATA, CODE_GET_VALID , CODE_SET_VALID } from '../actions/types'
//Variable To Customize  : car_type
const INIT_STATE = {
  ticketId: 0,
  car_pic: 0,
  validationCodeFromServer: "",
  booking: {},
  car_type: {
    BAYCAMRYTEST: {  privilege_id: 22 , coupon_id: 21 , value: 'CAMRY', car: ['CAMRY'], picIndex: 1 , noCodeLeangth: [6]},
    BAYHYUNDAITEST: {  privilege_id: 23 , coupon_id: 22 ,value: 'H1', car: ['H1'], picIndex: 0 , noCodeLeangth: [6] },
    BAYHYUNDAI: {  privilege_id: 23 , coupon_id: 22 ,value: 'H1', car: ['H1'], picIndex: 0  , noCodeLeangth: [6]},
    BAYCAMRY: {  privilege_id: 22 , coupon_id: 21 ,value: 'CAMRY', car: ['CAMRY'], picIndex: 1 , noCodeLeangth: [8]},
    CAMRYDM: {  privilege_id: 22 , coupon_id: 21 ,value: 'CAMRY', car: ['CAMRY'], picIndex: 1 , noCodeLeangth: [9]},
    BAYALPHARD: {  privilege_id: 24 , coupon_id: 23 ,value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4  , noCodeLeangth: [6,8]},
    BAYALPHARDTEST: {  privilege_id: 24 , coupon_id: 23 ,value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4  , noCodeLeangth: [6]},
  },
  mondayModel: {
    name: '',
    text: '',
  },
  carMaxDetail: {
    H1: {
      pax: '5 (28")',
      person: 5,
      luggage: 5,
      Msg: 'Hyundai H1',
    },
    CAMRY: {
      pax: '2 (28")',
      person: 2,
      luggage: 2,
      Msg: 'Toyota Camry',
    },
    BENBMW: {
      pax: '3 (26") / 2 (28")',
      person: 3, 
      luggage: 2,
      Msg: '',
    },
    ALPHARD: {
      pax: '4 (28")',
      person: 4,
      luggage: 4,
      Msg: 'Toyota Alphard',
    },
  },
  coupon: {},
  coperate: {},
  branch: {},
  coupon_id: 0,
  coupon_code: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CODE_SET_DATA: {
      return {
        ...state,
        ticketId: action.payload.id,
      }
    }
     case CODE_SET_VALID: {
      return {
        ...state,
        validationCodeFromServer: action.payload.data,
      }
    }

    default:
      return state
  }
}
