// import React from 'react'
// import '../../assets/css/newmodal.css'

// function PopupNotice(props) {
//   return (
//     <div>
//     <div
//       className={'modal fade ' + (props.display ? 'show' : '')}
//       id="consent_form"
//       tabIndex="999999"
//       role="dialog"
//       style={props.display ? { display: 'block' } : {}}
//     >
//       <div className="modal-dialog modal-dialog-centered " role="document">
//         <div className="modal-content ">  <div className="detail-2">
//         <div className="modal-header">
         
//         </div>
//         <div className="modal-body text-center">
//         <h5 className="modal-title w-100 mb-4"  >
//             ประกาศ 
//           </h5>
//               <div>
//                 ตามที่ได้มีการประกาศสถานการณ์ฉุกเฉิน 
//               เนื่องด้วยสถานการณ์ไวรัส <strong> Covid-19 </strong> ในประเทศขณะนี้ 
//               เพื่อเพิ่มประสิทธิภาพในการควบคุมการระบาด และลดการสัญจร 
//               จึงได้มีการประกาศข้อกำหนด ห้ามบุคคลออกนอกเคหะสถาน ตั้งแต่เวลา <strong> 22.00 น.- 04.00 น. </strong> 
//               โดยมีผลบังคับตั้งแต่วันที่ 3 เมษายน 2563 เป็นต้นไป 
//               </div>
//               <br/>
//               <div>
//               ทั้งนี้ทาง <strong> Silver Voyage Limousine </strong>  
//               จึงขอแจ้งปรับเวลาการให้บริการรถรับ-ส่งสนามบิน โดยจะให้บริการตั้งแต่เวลา <strong> 07.00 - 20.00 น.  </strong> 
//               ตั้งแต่วันที่  <strong> 3 เมษายน 2563 เป็นต้นไป </strong>   จนกว่ามีการเปลี่ยนแปลง 
//               </div>
//               <br/>
//               <div>
//               สำหรับลูกค้าท่านใดที่มีการสำรองรถเข้ามาก่อนหน้านี้ ทางบริษัทฯ 
//               </div>
//               <div>
//               ทางบริษัทฯ ขอนุญาตติดต่อกลับเพื่อเรียนแจ้งข้อมูลให้กับลูกค้าต่อไป
//               </div>
            
//               <br/> 
//               <div>
//               จึงเรียนมาเพื่อทราบ
//             </div> 
//         </div>
//         <div className="modal-footer text-center d-block pt-0 my-4">
//           <button
//             type="button"
//             className="btn btn-primary btn-md"
//             onClick={() => {
//               props.closePopup()
//             }}
//           >
//             CLOSE
//           </button>
//         </div>
//       </div>
//   </div>

  
//       </div>
//     </div>
//   </div>
//   )
// }

// export default PopupNotice


import React from 'react'
import '../../assets/css/newmodal.css'
import renderHTML from 'react-render-html';

function PopupNotice(props) {
  let x = false;
  return (
    <div>
    <div
      className={'modal fade ' + (props.display ? 'show' : '')}
      // id="consent_form"
      tabIndex="999999"
      role="dialog"
      style={props.display ? { display: 'block' } : {}}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
        <div className="modal-content ">  <div className="detail-2">
        <div className="modal-header">
         
        </div>
        <div className="modal-body text-center" id="modal-body">
          
          {renderHTML(`<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="PrivacyPolicy(TH)(2)_files/filelist.xml">
<!--[if gte mso 9]><xml>
 <o:DocumentProperties>
  <o:Author>Kamonpop Khamphen</o:Author>
  <o:Template>Normal</o:Template>
  <o:LastAuthor>แบ้ง - Chatchawat Kumyong</o:LastAuthor>
  <o:Revision>2</o:Revision>
  <o:TotalTime>15</o:TotalTime>
  <o:Created>2020-08-18T04:10:00Z</o:Created>
  <o:LastSaved>2020-08-18T04:10:00Z</o:LastSaved>
  <o:Pages>1</o:Pages>
  <o:Words>3858</o:Words>
  <o:Characters>21995</o:Characters>
  <o:Lines>183</o:Lines>
  <o:Paragraphs>51</o:Paragraphs>
  <o:CharactersWithSpaces>25802</o:CharactersWithSpaces>
  <o:Version>16.00</o:Version>
 </o:DocumentProperties>
 <o:OfficeDocumentSettings>
  <o:AllowPNG/>
 </o:OfficeDocumentSettings>
</xml><![endif]-->
<link rel=dataStoreItem href="PrivacyPolicy(TH)(2)_files/item0001.xml"
target="PrivacyPolicy(TH)(2)_files/props002.xml">
<link rel=themeData href="PrivacyPolicy(TH)(2)_files/themedata.thmx">
<link rel=colorSchemeMapping
href="PrivacyPolicy(TH)(2)_files/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-US</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>TH</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:ApplyBreakingRules/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;
	mso-font-charset:2;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:0 268435456 0 0 -2147483648 0;}
@font-face
	{font-family:"Angsana New";
	panose-1:2 2 6 3 5 4 5 2 3 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-2130706429 0 0 0 65537 0;}
@font-face
	{font-family:"Cordia New";
	panose-1:2 11 3 4 2 2 2 2 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-2130706429 0 0 0 65537 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073732485 9 0 511 0;}
@font-face
	{font-family:Sarabun;
	mso-font-alt:"Browallia New";
	mso-font-charset:0;
	mso-generic-font-family:auto;
	mso-font-pitch:variable;
	mso-font-signature:553648135 1 0 0 65939 0;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073683329 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-priority:99;
	mso-style-link:"Header Char";
	margin:0in;
	mso-pagination:widow-orphan;
	tab-stops:center 3.25in right 6.5in;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-priority:99;
	mso-style-link:"Footer Char";
	margin:0in;
	mso-pagination:widow-orphan;
	tab-stops:center 3.25in right 6.5in;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Balloon Text Char";
	margin:0in;
	mso-pagination:widow-orphan;
	font-size:9.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-bidi-font-family:"Angsana New";}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	mso-add-space:auto;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Balloon Text";
	mso-ansi-font-size:9.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-ascii-font-family:"Segoe UI";
	mso-hansi-font-family:"Segoe UI";
	mso-bidi-font-family:"Angsana New";}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:Footer;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
.MsoPapDefault
	{mso-style-type:export-only;
	margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page
	{mso-footnote-separator:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") fs;
	mso-footnote-continuation-separator:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") fcs;
	mso-endnote-separator:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") es;
	mso-endnote-continuation-separator:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") ecs;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;
	mso-header-margin:.5in;
	mso-footer-margin:.5in;
	mso-header:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") h1;
	mso-footer:url("PrivacyPolicy\(TH\)\(2\)_files/header.htm") f1;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:148399987;
	mso-list-type:hybrid;
	mso-list-template-ids:-403282000 778084336 1319162000 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l0:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-font-weight:normal;
	mso-bidi-font-weight:normal;}
@list l0:level2
	{mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.5in;
	text-indent:-.25in;
	mso-ansi-font-size:14.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Times New Roman",serif;
	mso-ascii-font-family:"Cordia New";
	mso-ascii-theme-font:minor-bidi;
	mso-hansi-font-family:"Cordia New";
	mso-hansi-theme-font:minor-bidi;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;
	mso-ansi-font-weight:bold;
	mso-bidi-font-weight:bold;}
@list l0:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:2.0in;
	text-indent:-9.0pt;}
@list l0:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.5in;
	text-indent:-.25in;}
@list l0:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.0in;
	text-indent:-.25in;}
@list l0:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.5in;
	text-indent:-9.0pt;}
@list l0:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.0in;
	text-indent:-.25in;}
@list l0:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.5in;
	text-indent:-.25in;}
@list l0:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:5.0in;
	text-indent:-9.0pt;}
@list l1
	{mso-list-id:388655411;
	mso-list-type:hybrid;
	mso-list-template-ids:-875372314 1509426728 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l1:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.25in;
	mso-bidi-language:TH;
	mso-ansi-font-weight:bold;
	mso-bidi-font-weight:bold;}
@list l1:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-.25in;}
@list l1:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:1.75in;
	text-indent:-9.0pt;}
@list l1:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.25in;
	text-indent:-.25in;}
@list l1:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.75in;
	text-indent:-.25in;}
@list l1:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.25in;
	text-indent:-9.0pt;}
@list l1:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.75in;
	text-indent:-.25in;}
@list l1:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.25in;
	text-indent:-.25in;}
@list l1:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:4.75in;
	text-indent:-9.0pt;}
@list l2
	{mso-list-id:525099717;
	mso-list-type:hybrid;
	mso-list-template-ids:-261822954 -1981664908 -455855078 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l2:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:20.25pt;
	text-indent:-.25in;
	mso-ansi-font-size:14.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Times New Roman",serif;
	mso-ascii-font-family:"Cordia New";
	mso-ascii-theme-font:minor-bidi;
	mso-hansi-font-family:"Cordia New";
	mso-hansi-theme-font:minor-bidi;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;
	mso-bidi-language:TH;
	mso-ansi-font-weight:bold;
	mso-bidi-font-weight:bold;}
@list l2:level2
	{mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:74.25pt;
	text-indent:-.5in;}
@list l2:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:92.25pt;
	text-indent:-9.0pt;}
@list l2:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:128.25pt;
	text-indent:-.25in;}
@list l2:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:164.25pt;
	text-indent:-.25in;}
@list l2:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:200.25pt;
	text-indent:-9.0pt;}
@list l2:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:236.25pt;
	text-indent:-.25in;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:272.25pt;
	text-indent:-.25in;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:308.25pt;
	text-indent:-9.0pt;}
@list l3
	{mso-list-id:1401295961;
	mso-list-template-ids:879291242;}
@list l3:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l3:level2
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.5in;
	mso-ansi-font-size:14.0pt;}
@list l3:level3
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.5in;
	mso-ansi-font-size:14.0pt;}
@list l3:level4
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.5in;
	mso-ansi-font-size:14.0pt;}
@list l3:level5
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4\.%5";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:.75in;
	text-indent:-.5in;
	mso-ansi-font-size:14.0pt;}
@list l3:level6
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.75in;
	mso-ansi-font-size:14.0pt;}
@list l3:level7
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.75in;
	mso-ansi-font-size:14.0pt;}
@list l3:level8
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-1.0in;
	mso-ansi-font-size:14.0pt;}
@list l3:level9
	{mso-level-legal-format:yes;
	mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.25in;
	text-indent:-1.0in;
	mso-ansi-font-size:14.0pt;}
@list l4
	{mso-list-id:1483278314;
	mso-list-type:hybrid;
	mso-list-template-ids:-1376606362 67698689 67698691 67698693 67698689 67698691 67698693 67698689 67698691 67698693;}
@list l4:level1
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Symbol;}
@list l4:level2
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l4:level3
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Wingdings;}
@list l4:level4
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Symbol;}
@list l4:level5
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l4:level6
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Wingdings;}
@list l4:level7
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Symbol;}
@list l4:level8
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l4:level9
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Wingdings;}
@list l5
	{mso-list-id:1565412218;
	mso-list-type:hybrid;
	mso-list-template-ids:-1480446180 778084336 -1732209918 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l5:level1
	{mso-level-text:"%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.25in;
	mso-ansi-font-size:11.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-font-weight:normal;
	mso-bidi-font-weight:normal;}
@list l5:level2
	{mso-level-text:"%2\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.5in;
	text-indent:-.25in;
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	font-family:Sarabun;
	mso-ansi-font-weight:normal;
	mso-bidi-font-weight:normal;}
@list l5:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:2.0in;
	text-indent:-9.0pt;}
@list l5:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.5in;
	text-indent:-.25in;}
@list l5:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.0in;
	text-indent:-.25in;}
@list l5:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:3.5in;
	text-indent:-9.0pt;}
@list l5:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.0in;
	text-indent:-.25in;}
@list l5:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.5in;
	text-indent:-.25in;}
@list l5:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:5.0in;
	text-indent:-9.0pt;}
@list l6
	{mso-list-id:1699504742;
	mso-list-type:hybrid;
	mso-list-template-ids:973114556 67698689 67698691 67698693 67698689 67698691 67698693 67698689 67698691 67698693;}
@list l6:level1
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.0in;
	text-indent:-.25in;
	font-family:Symbol;}
@list l6:level2
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:1.5in;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l6:level3
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.0in;
	text-indent:-.25in;
	font-family:Wingdings;}
@list l6:level4
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:2.5in;
	text-indent:-.25in;
	font-family:Symbol;}
@list l6:level5
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.0in;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l6:level6
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:3.5in;
	text-indent:-.25in;
	font-family:Wingdings;}
@list l6:level7
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.0in;
	text-indent:-.25in;
	font-family:Symbol;}
@list l6:level8
	{mso-level-number-format:bullet;
	mso-level-text:o;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:4.5in;
	text-indent:-.25in;
	font-family:"Courier New";}
@list l6:level9
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:5.0in;
	text-indent:-.25in;
	font-family:Wingdings;}
ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0in 5.4pt 0in 5.4pt;
	mso-para-margin-top:0in;
	mso-para-margin-right:0in;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:0in;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	mso-bidi-font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Cordia New";
	mso-bidi-theme-font:minor-bidi;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=EN-US style='tab-interval:.5in'>

<div class=WordSection1>

<p class=MsoNormal align=center style='margin-bottom:12.0pt;text-align:center;
line-height:normal'><b><span lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>นโยบายความเป็นส่วนตัว</span></b><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><br>
<span lang=TH>บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด </span></span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัท
เวิลด์ รีวอร์ด โซลูชั่น จำกัด </span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-spacerun:yes'> </span>(<span lang=TH>“<b>บริษัทฯ</b>”<b>บริษัทฯ</b>”)
ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล ของผู้ที่จะเป็นลูกค้า ลูกค้า
หรือผู้ค้าปลีก </span>(<span lang=TH>“<b>ท่าน</b>”) ในสินค้าและบริการของบริษัทฯ
บริษัทฯปฏิบัติตามขั้นตอนเพื่อความปลอดภัยอย่างเคร่งครัดในการเก็บ ใช้ เปิดเผย
และ/หรือ ถ่ายโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังต่างประเทศ ข้อมูลที่ท่านแบ่งปันให้กับบริษัทฯ
ทำให้บริษัทฯ รวมถึง บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ สามารถนำเสนอสินค้าและบริการที่ตรงกับความต้องการของท่าน
พร้อมมอบประสบการณ์และบริการลูกค้าแบบเฉพาะตัวที่ดีที่สุดให้แก่ท่าน</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>นโยบายความเป็นส่วนตัวฉบับนี้
(“<b>นโยบายความเป็นส่วนตัวฯ</b>”) ใช้กับธุรกิจ เว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่
</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>Call Center<span lang=TH> สายด่วนตอบข้อซักถาม เว็บไซต์เครือข่ายสังคม
ช่องทางการสื่อสารออนไลน์ และสถานที่อื่น ๆ ที่บริษัทฯเก็บข้อมูลส่วนบุคคลฯ
ของท่าน</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาจเปลี่ยนแปลง
และ/หรือปรับปรุงนโยบายความเป็นส่วนตัวฯ นี้เป็นครั้งคราว โดยบริษัทฯจะแจ้งท่านเพิ่มเติมหากมีการปรับปรุงที่สำคัญ
บริษัทฯจะระบุวันที่นโยบายความเป็นส่วนตัวฯ ของบริษัทฯได้มีการปรับปรุงครั้งล่าสุดไว้ทางด้านบนของนโยบายความเป็นส่วนตัวฯ
ทั้งนี้ บริษัทฯสนับสนุนให้ท่านอ่านนโยบายความเป็นส่วนตัวฯ นี้อย่างระมัดระวัง
และตรวจสอบนโยบายความเป็นส่วนตัวฯ อย่างสม่ำเสมอเพื่อทบทวนการเปลี่ยนแปลงใด ๆ ที่บริษัทฯอาจดำเนินการตามข้อกำหนดในนโยบายความเป็นส่วนตัวฯ
ฉบับนี้</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>1.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯเก็บข้อมูลส่วนบุคคลอะไร</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อวัตถุประสงค์แห่งนโยบายความเป็นส่วนตัวฯ
ฉบับนี้ คำว่า “<b>ข้อมูลส่วนบุคคลฯ</b>” หมายความถึง
ข้อมูลที่ระบุหรือที่สามารถระบุตัวตนของท่านได้ ตามรายการที่ระบุด้านล่างนี้<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาจเก็บข้อมูลส่วนบุคคลฯ
ของท่านโดยตรงหรือโดยอ้อมจากแหล่งที่มาอื่น ๆ ซึ่งรวมถึง<a name="_Hlk34406566"> บริษัทในเครือ
และบริษัทย่อย ผู้ให้บริการของบริษัทฯ และคู่ค้าทางธุรกิจของบริษัทฯซึ่งเป็นบุคคลที่สาม
</a>ประเภทของข้อมูลที่เก็บจะขึ้นอยู่กับบริบทของปฏิสัมพันธ์ระหว่างบริษัทฯกับท่าน
และบริการหรือสินค้าที่ท่านประสงค์จากบริษัทฯ ตัวอย่างของข้อมูลส่วนบุคคลฯ
ที่อาจเก็บมีดังนี้</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>1)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดส่วนบุคคล</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ อาชีพ ตำแหน่งงาน ตำแหน่ง ประเภทธุรกิจ
สัญชาติ วันเดือนปีเกิด สถานภาพทางการสมรส ทะเบียนสมรส
จำนวนสมาชิกในครอบครัวและบุตร ข้อมูลบนบัตรที่ออกโดยหน่วยงานราชการ (เช่น
เลขประจำตัวประชาชน สำเนาบัตรประจำตัวประชาชน หมายเลขหนังสือเดินทาง
รายละเอียดใบอนุญาตขับขี่) ทะเบียนบ้าน ลายมือชื่อ เสียง บันทึกเสียง รูป ภาพ
รูปถ่าย บันทึกวิดีโอ คลิปวิดีโอ ประวัติทางการศึกษา สถานที่ทำงาน รายได้ น้ำหนักและส่วนสูง
บันทึกโทรทัศน์วงจรปิด </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>2)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดในการติดต่อ</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
เช่น ที่อยู่ รายละเอียดในการจัดส่ง รายละเอียดในการเรียกเก็บเงิน หมายเลขโทรศัพท์
หมายเลขโทรศัพท์เคลื่อนที่ หมายเลขโทรศัพท์ที่ทำงาน อีเมล อีเมลที่ทำงาน ชื่อบัญชีไลน์
บัญชีเฟซบุ๊ก บัญชีกูเกิล บัญชีทวิตเตอร์ และบัญชีอื่น ๆ ที่เกี่ยวกับเว็บไซต์เครือข่ายสังคม
</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>3)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดทางบัญชี</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
เช่น หมายเลขบัตรเครดิต/เดบิต ข้อมูลบัตรเครดิต/เดบิต หมายเลขบัญชีธนาคาร รหัสสมาชิก
รหัสลูกค้า ประเภทสมาชิก ประเภทลูกค้า คะแนนเครดิตลูกค้า ใบสมัครขอรับสินค้าและบริการ
เดือนที่เข้าเป็นสมาชิก/รับบริการ และรายละเอียดการชำระเงิน และสำเนาบัญชีธนาคาร/สมุดบัญชีธนาคาร</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>4)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดธุรกรรม</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
เช่น ข้อมูลการชำระเงิน ข้อมูลการใช้บัตรและข้อมูลธุรกรรม (เช่น ข้อมูลและบันทึกการใช้และธุรกรรม
ข้อมูล<span style='color:black;'>กลุ่มลูกค้าเป้าหมาย </span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>(Lead) </span><span lang=TH style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ข้อมูลการตอบสนองต่อแคมเปญโฆษณา
รายละเอียดสลิปชำระเงินเกี่ยวกับการคืนเงิน จำนวนเงินที่คืน คะแนน
วันและสถานที่ที่ใช้จ่าย หมายเลขการสั่งจอง/คำสั่งจอง วันที่นัดรับบริการ
ข้อร้องเรียนและข้อเรียกร้อง ธุรกรรม ประวัติธุรกรรม สถานที่ สถานะธุรกรรม
ธุรกรรมการขายที่ผ่านมา และพฤติกรรมการใช้จ่าย ตลอดจนรายละเอียดอื่น ๆ ของสินค้าและบริการที่ท่านได้จองกับบริษัทฯ</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>5)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดทางเทคนิค</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
เช่น </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>Internet Portal Address (IP Address)<span lang=TH> <span
style='mso-spacerun:yes'> </span></span>Universal Device Identifier (UDID)<span
lang=TH> หรือ </span>MAC Address <span lang=TH>รหัสอ้างอิงซอฟต์แวร์ เช่น ข้อมูลตัวระบุโฆษณาสำหรับระบบปฏิบัติการไอโอเอส
</span>(IDFA) <span lang=TH>หรือข้อมูลตัวระบุโฆษณาสำหรับระบบปฏิบัติการแอนดรอยด์
</span>(AAID) <span lang=TH>เครือข่าย รายละเอียดการเชื่อมต่อ
รายละเอียดการเข้าถึง
การเข้าถึงการใช้บริการของระบบด้วยการพิสูจน์ตัวตนเพียงครั้งเดียว บันทึกการเข้าสู่ระบบ
</span>(Login Log) <span lang=TH>บันทึกเวลาการเข้าใช้ระบบ<span
style='color:black;'> ระยะเวลาที่ใช้ในการเยี่ยมชมหน้าเพจของบริษัทฯ
คุกกี้ ข้อมูลการเข้าสู่ระบบของท่าน ประวัติการค้นหา รายละเอียดการค้นหา
ประเภทและเวอร์ชั่นของเบราว์เซอร์ ที่ตั้งและตำแหน่งของเขตเวลา ประเภทและเวอร์ชั่นของเบราว์เซอร์ปลั๊กอิน
ระบบและแพลตฟอร์มปฏิบัติการ และเทคโนโลยีอื่น ๆ
บนอุปกรณ์ที่ท่านใช้ในการเข้าถึงแพลตฟอร์ม</span></span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>6)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'>รายละเอียดพฤติกรรม</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'> เช่น ข้อมูลที่มาจากการที่ท่านใช้</span><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>สินค้า<span
style='color:black;'>และบริการของบริษัทฯ เช่น ตำแหน่งที่ท่านอยู่
ตำแหน่งที่ท่านเดินทาง</span></span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>7)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียด<span style='color:black;
'>โปรไฟล์</span></span></b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'> เช่น
ชื่อผู้ใช้และรหัสผ่าน โปรไฟล์ การใช้จ่าย ประวัติคำสั่งจอง การสั่งจองที่ผ่านมา
ประวัติการจอง รายการที่จอง จำนวนที่จองต่อรายการ การยกเลิกคำสั่งจองหรือการคืนสินค้าจากท่าน
คำสั่งจองผ่านทางเว็บไซต์ รหัสคำสั่งจอง บันทึกทางการเงิน หมายเลขยืนยันตัวตน (</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>PIN<span lang=TH>) ข้อมูลเกี่ยวกับความสนใจ
ความชื่นชอบ ผลตอบรับและผลการตอบแบบสำรวจ ผลสำรวจความพึงพอใจ การใช้โซเชียลมีเดีย
รายละเอียดการเข้าร่วมกิจกรรม โปรแกรมสะสมคะแนน </span>(Loyalty Program) <span
lang=TH>การใช้รหัสส่วนลดและโปรโมชั่นของท่าน รายละเอียดคำสั่งจอง การบริการลูกค้า
</span>(CRM)<span lang=TH> </span></span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;letter-spacing:
-.2pt'><span style='mso-list:Ignore'>8)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;letter-spacing:-.2pt'>รายละเอียด<span
style='color:black;'>การใช้</span></span></b><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;letter-spacing:-.2pt;'> เช่น ข้อมูลการใช้เว็บไซต์
แพลตฟอร์ม สินค้า และบริการของท่าน บันทึกถาม</span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;letter-spacing:-.2pt;
'>-<span lang=TH>ตอบ และ/หรือ</span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
letter-spacing:-.2pt'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l1 level1 lfo2'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>9)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'>รายละเอียดการตลาดและ</span></b><b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>การ<span
style='color:black;'>สื่อสาร</span></span></b><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'> เช่น ความชื่นชอบของท่านในการรับข่าวสารด้านการตลาดจากบริษัทฯ
</span><span lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>บริษัทในเครือ และบริษัทย่อย บุคคลที่สาม คู่ค้าทางธุรกิจ และความชื่นชอบในการติดต่อสื่อสารของท่าน</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>นอกจากนี้
ข้อมูลส่วนบุคคลฯ ของท่านอาจถูกเก็บรวบรวมจากคู่ค้าทางธุรกิจของบริษัทฯ
ในกรณีที่ท่านจองสินค้าจากคู่ค้าทางธุรกิจผ่านเว็บไซต์ของบริษัทฯ ทั้งนี้ บริษัทฯจะได้รับข้อมูลส่วนบุคคลฯ
ของท่าน ในส่วนที่เกี่ยวข้องกับสินค้าที่ท่านได้จอง เพื่อวัตถุประสงค์ในการติดตามผลธุรกรรมการขายและการพัฒนาการให้บริการ</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ในกรณีที่ท่านให้ข้อมูลส่วนบุคคลฯ
ของบุคคลอื่นใด (เช่น เพื่อน ผู้ปกครอง) กับบริษัทฯ เช่น ชื่อ นามสกุล
หมายเลขโทรศัพท์ ไม่ว่าจะเป็นไปเพื่อการจัดส่งสินค้าและบริการของบริษัทฯ
หรือเพื่อการติดต่อในกรณีฉุกเฉิน ท่านรับรองว่าท่านมีอำนาจที่จะดำเนินการเช่นนั้นได้และอนุญาตให้บริษัทฯใช้ข้อมูลส่วนบุคคลฯ
ดังกล่าวตามนโยบายความเป็นส่วนตัวฯ ฉบับนี้</span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ
ไม่มีเจตนาที่จะเก็บ ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (“<b>ข้อมูลที่มีความอ่อนไหวฯ</b>”)
ของท่าน อย่างไรก็ดี ในกรณีที่บริษัทฯ ดำเนินการดังกล่าว บริษัทฯ จะเก็บ ใช้
และ/หรือ เปิดเผยข้อมูลที่มีความอ่อนไหวฯ บนฐานของความยินยอมโดยชัดแจ้งจากท่าน เช่น
ประวัติการแพ้ยา ประวัติการแพ้อาหาร หรือเท่าที่กฎหมายอนุญาตให้กระทำได้เท่านั้น <span
style='letter-spacing:-.1pt'><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯจะเก็บข้อมูลส่วนบุคคลฯ
ของผู้เยาว์ บุคคลเสมือนไร้ความสามารถ และบุคคลไร้ความสามารถ เมื่อผู้ปกครอง ผู้พิทักษ์
หรือผู้อนุบาล (แล้วแต่กรณี) ของบุคคลดังกล่าวได้ให้ความยินยอมแล้วเท่านั้น บริษัทฯไม่มีเจตนาที่จะเก็บข้อมูลส่วนบุคคลฯ
ของลูกค้าที่มีอายุต่ำกว่า </span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>20<span lang=TH> ปี โดยปราศจากความยินยอมของผู้ปกครองหากกฎหมายกำหนดให้ต้องได้รับความยินยอมจากบุคคลดังกล่าว
หรือของลูกค้าที่เป็นบุคคลเสมือนไร้ความสามารถหรือบุคคลไร้ความสามารถโดยปราศจากความยินยอมจากผู้พิทักษ์หรือผู้อนุบาล
(แล้วแต่กรณี) ตามกฎหมาย ในกรณีที่บริษัทฯพบว่าบริษัทฯได้เก็บข้อมูลส่วนบุคคลฯ ของผู้ที่มีอายุต่ำกว่า
</span>20<span lang=TH> ปี โดย<span style='letter-spacing:-.1pt'>ปราศจาก</span>ความยินยอมจากผู้ปกครองตามที่กฎหมายกำหนด
หรือของผู้ที่เป็นบุคคลเสมือนไร้ความสามารถ หรือบุคคลไร้ความสามารถโดยปราศจากความยินยอมจากผู้พิทักษ์หรือผู้อนุบาล
(แล้วแต่กรณี) โดยมิได้เจตนา บริษัทฯจะลบข้อมูลส่วนบุคคลฯ ดังกล่าวโดยทันที
เว้นแต่บริษัทฯจะสามารถอาศัยฐานทางกฎหมายอื่น นอกเหนือจากความยินยอม</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>2.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>ทำไมบริษัทฯจึงเก็บ ใช้ และ/หรือ
เปิดเผยข้อมูลส่วนบุคคลฯ ของท่าน</span></b><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาจเก็บ ใช้
หรือเปิดเผยข้อมูลส่วนบุคคลฯ ของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>2.1<span style='mso-tab-count:1'>   </span>วัตถุประสงค์ตามที่ท่านได้ให้ความยินยอมไว้</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาศัยความยินยอมของท่านในการเก็บ
ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลฯ ของท่าน โดยบริษัทฯ บริษัทในกลุ่ม
บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ จะใช้ข้อมูลส่วนบุคคลฯ ของท่าน
เพื่อวัตถุประสงค์ดังต่อไปนี้</span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-justify:inter-cluster;text-indent:
-.5in;line-height:normal'><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>1)<span style='mso-tab-count:1'>   </span><b>การตลาดและการติดต่อสื่อสาร</b></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: <span
lang=TH>เพื่อการติดต่อสื่อสารด้านการตลาด การให้ข้อมูล ข้อเสนอพิเศษ เอกสารส่งเสริมการขาย
การตลาดทางโทรศัพท์ สิทธิพิเศษ การโฆษณา จดหมายข่าว การตลาดและการติดต่อสื่อสารอื่นใด
ทั้งช่องทางออนไลน์และออฟไลน์ เกี่ยวกับสินค้าและบริการจากบริษัทฯ บริษัทในเครือ
และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ ซึ่งบริษัทฯไม่สามารถอาศัยฐานทางกฎหมายอื่น ๆ
ได้</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-justify:inter-cluster;text-indent:
-.5in;line-height:normal'><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>2)<span style='mso-tab-count:1'>    </span><b>การวิเคราะห์ข้อมูล</b></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:<span
lang=TH> เพื่อให้บริการวิเคราะห์ข้อมูล</span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>หากท่านไม่ประสงค์ให้ความยินยอม หรือต้องการเพิกถอนความยินยอมของท่าน
เพื่อวัตถุประสงค์ข้างต้น อาจทำให้บริษัทฯไม่สามารถให้บริการของบริษัทฯแก่ท่านได้</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;text-justify:inter-cluster;text-indent:
-.5in;line-height:normal;tab-stops:.5in'><b><span lang=TH style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;
'>2.2<span style='mso-tab-count:1'>   </span><span
style='letter-spacing:-.5pt'>วัตถุประสงค์โดยอาศัยเหตุทางกฎหมายเพื่อดำเนินการกับข้อมูลส่วนบุคคลฯ
ของท่าน</span></span></b><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi;color:black;letter-spacing:-.5pt;'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ
อาจอาศัย (</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>1<span lang=TH>) ฐานสัญญา เพื่อการเริ่มต้นหรือการปฏิบัติตามสัญญาต่อท่าน
(</span>2<span lang=TH>) ฐานหน้าที่ตามกฎหมาย เพื่อการปฏิบัติหน้าที่ตามกฎหมายของบริษัทฯ
(</span>3<span lang=TH>) ฐานประโยชน์อันชอบธรรม เพื่อประโยชน์อันชอบธรรมของบริษัทฯและบุคคลที่สาม
ทั้งนี้ บริษัทฯจะ<span style='letter-spacing:-.1pt'>ดำเนินการ</span>ให้เกิดความสมดุลระหว่างประโยชน์อันชอบธรรมของบริษัทฯและบุคคลที่สามที่เกี่ยวข้อง
กับผลประโยชน์ของท่าน รวมถึงสิทธิและเสรีภาพขั้นพื้นฐานเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลฯ
ของท่าน (</span>4<span lang=TH>) ฐานประโยชน์สำคัญต่อชีวิต เพื่อการป้องกันหรือยับยั้งอันตรายต่อชีวิต
ร่างกาย หรือสุขภาพของบุคคลใด ๆ และ/หรือ (</span>5<span lang=TH>) ฐานประโยชน์สาธารณะ
เพื่อการดำเนินภารกิจ เพื่อประโยชน์สาธารณะ หรือเพื่อการใช้อำนาจของเจ้าหน้าที่
ทั้งนี้ เพื่อวัตถุประสงค์ดังต่อไปนี้</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>1)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อให้สินค้าและบริการแก่ท่าน</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>เพื่อลงทะเบียนและให้ท่านสามารถใช้สินค้าหรือบริการของบริษัทฯ ซึ่งรวมถึงแต่ไม่จำกัดเพียง
เพื่อให้ท่านสามารถใช้เว็บไซต์ <a name="_Hlk34657143">แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่</a>
และ<span style='color:black;'>แพลตฟอร์ม</span>ของบริษัทฯ เพื่อเข้าทำสัญญาและจัดการนิติสัมพันธ์ระหว่างบริษัทฯ
กับท่าน เพื่อสนับสนุนและดำเนินกิจกรรมอื่น ๆ เกี่ยวกับบริการหรือสินค้าดังกล่าว
เพื่อขายสินค้าหรือบริการของบริษัทฯ ผ่านช่องทางออนไลน์และออฟไลน์ เพื่อค้าส่งสินค้าหรือบริการของบริษัทฯ
แก่ลูกค้าองค์กรของบริษัทฯ เพื่อให้บริการสื่อออนไลน์และการตลาดดิจิทัล เพื่อประมวลธุรกรรมระหว่างท่านและคู่ค้าทางธุรกิจของบริษัทฯ
เพื่อจัดการการจองและเพื่อการดำเนินธุรกรรมทางการเงิน ตลอดจนบริการที่เกี่ยวข้องกับการชำระเงิน
ซึ่งรวมถึงการตรวจสอบ การพิสูจน์ยืนยัน และการยกเลิกธุรกรรม เพื่อดำเนินการเกี่ยวกับคำสั่งจอง
การจัดส่ง การระงับชั่วคราว การทดแทน การชดเชยเงินคืน การคืน
และการแลกเปลี่ยนสินค้าหรือบริการของท่าน และเพื่อให้การบริการลูกค้า</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>2)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อการติดต่อสื่อสารด้านการตลาด</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: <span
lang=TH>เพื่อการติดต่อสื่อสารด้านการตลาด การให้ข้อมูล ข้อเสนอพิเศษ
เอกสารส่งเสริมการขาย การตลาดทางโทรศัพท์ สิทธิพิเศษ การโฆษณา จดหมายข่าว
การตลาดและการติดต่อสื่อสารอื่นใด ทั้งช่องทางออนไลน์และออฟไลน์
เกี่ยวกับสินค้าและบริการจากบริษัทฯ บริษัทในเครือ และบริษัทย่อย
ตลอดจนคู่ค้าทางธุรกิจ</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>3)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อเสนอโปรโมชั่น ข้อเสนอพิเศษ <span
style='color:black;'>โปรแกรมสะสมแต้มและของรางวัล การจับรางวัล
การแข่งขัน และข้อเสนออื่น ๆ แก่ท่าน</span></span></b><b><span style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;
'>:</span></b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi;color:black;'> <span lang=TH>เพื่อให้ท่านสามารถเข้าร่วมโปรโมชั่น
แคมเปญส่งเสริมการขาย ข้อเสนอพิเศษ ข้อเสนอส่งเสริมการขาย โปรแกรมสะสมแต้ม โปรแกรม<a
name="_Hlk34654309">ลงทะเบียนร่วม</a>กับคู่ค้าทางธุรกิจของบริษัทฯ การชิงโชค
สิทธิพิเศษ การจับรางวัล การแข่งขัน และข้อเสนออื่น ๆ (เช่น
เพื่อส่งอีเมลแจ้งเตือน) งานอีเว้นท์ และงานสัมมนา ทั้งนี้
รวมถึงเพื่อดำเนินการและบริหารจัดการด้านการลงทะเบียนบัญชี การลงทะเบียนรับของขวัญ
การลงทะเบียนเข้างานอีเว้นท์ของท่าน เพื่อประมวลผลการเก็บ เพิ่ม แลกเปลี่ยน ได้รับ
แลก และโอนคะแนนสะสม เพื่อประเมินประวัติการใช้งานทั้งหมดของท่านทั้งทางออนไลน์และออฟไลน์
และเพื่อให้และออกบัตรกำนัล บัตรของขวัญ และใบแจ้งหนี้</span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>4)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อติดต่อสื่อสารกับท่าน</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>เพื่อให้ข้อมูล สื่อสารทางการตลาด แคมเปญโฆษณา โฆษณา การแจ้งเตือนที่จำเป็น
ข้อเสนอพิเศษ สิทธิประโยชน์ และเอกสารส่งเสริมการขายสินค้าหรือบริการของบริษัทฯแก่ท่าน
เพื่อส่งข่าวสาร จดหมายข่าวอิเล็กทรอนิกส์ ข้อความการตลาด
และข้อมูลเกี่ยวกับสินค้า บริการ แบรนด์ และการดำเนินงานแก่ท่าน</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>5)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อจัดการความสัมพันธ์ของบริษัทฯกับท่าน</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อติดต่อสื่อสารกับท่านเกี่ยวกับสินค้าและบริการที่ท่านได้รับจากบริษัทฯ
บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯ
เพื่อดูแลบริการลูกค้า </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>Call Center<span lang=TH> และ/หรือ สายด่วนตอบข้อซักถาม คำขอ <span
style='color:black;'>ผลตอบรับ ข้อร้องเรียน ข้อเรียกร้อง
ข้อพิพาท หรือการชดเชยค่าเสียหาย
เพื่อให้ความช่วยเหลือทางเทคนิคและจัดการกับปัญหาทางเทคนิค เพื่อประมวลผลและปรับปรุงข้อมูลของท่าน
และเพื่ออำนวยความสะดวกแก่ท่านในการใช้สินค้าและบริการ</span></span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>6)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'>เพื่อการตรวจสอบ
</span></b><b><span lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>แจก<span style='color:black;'>แจง และวิเคราะห์ข้อมูล</span></span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>: </span></b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'>เพื่อวัดการมีส่วนร่วมของท่านกับสินค้าและบริการ</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>,<span lang=TH> เพื่อตรวจสอบและจับคู่ข้อมูล แจกแจงคุณลักษณะของข้อมูล
และวิเคราะห์ข้อมูล เพื่อวิจัยด้านการตลาด สำรวจ ประเมิน พฤติกรรม สถิติ
และการแบ่งส่วนตลาด แนวโน้มและรูปแบบการบริโภค เพื่อรู้จักท่านให้มากขึ้นและเข้าใจลักษณะของท่าน
เพื่อพัฒนาการดำเนินงานทางธุรกิจ เพื่อปรับปรุงเนื้อหาของบริษัทฯ ให้เข้ากับความชื่นชอบเฉพาะตัวของลูกค้าของบริษัทฯได้ดียิ่งขึ้น
เพื่อประเมินประสิทธิภาพแคมเปญส่งเสริมการขายของบริษัทฯ เพื่อระบุและแก้ปัญหาในสินค้าและบริการที่มีอยู่ในปัจจุบัน
เพื่อเสริมการพัฒนาข้อมูลเชิงคุณภาพให้ดีขึ้น</span></span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>7)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อคัดสรรและเสนอสินค้าหรือบริการที่น่าจะอยู่ในความสนใจและเหมาะสมกับความต้องการของท่าน</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อให้บริษัทฯ<span
style='color:black;'> </span>บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯใช้ผลลัพธ์จาก<span
style='color:black;'>การตรวจสอบและจับคู่ข้อมูล การแจกแจงคุณลักษณะของข้อมูล
และการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลฯ ของท่าน เพื่อนำเสนอสินค้าและบริการที่อาจอยู่ในความสนใจแก่ท่าน</span>
เพื่อระบุความชื่นชอบของท่านและมอบประสบการณ์แบบเฉพาะตัวให้กับท่าน
และเพื่อพัฒนาเนื้อหาบทความในอนาคตให้ตรงกับความสนใจของท่าน</span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>8)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อพัฒนาการดำเนินงานทางธุรกิจ
สินค้า และบริการ</span></b><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>: </span></b><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อประเมินผล พัฒนา จัดการ
และปรับปรุงบริการ สินค้า ระบบ และการดำเนินงานทางธุรกิจที่มีอยู่ในปัจจุบัน
ตลอดจนออกแบบบริการ สินค้า ระบบ และการดำเนินงานทางธุรกิจใหม่ ให้แก่ท่านและลูกค้าทั้งหมดของบริษัทฯ
ซึ่งรวมถึงแต่ไม่จำกัดเพียง บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯ
เพื่อติดตามผลธุรกรรมการจองขายของท่าน (การติดตามการขาย) เพื่อพัฒนาการบริการของบริษัทฯ
เพื่อระบุและแก้ไขปัญหาต่าง ๆ เพื่อจัดทำรายงานรวมแบบไม่ระบุชื่อ และวัดผลการดำเนินงานของสินค้าทางกายภาพ
ทรัพย์สินดิจิทัล และแคมเปญการตลาด และเพื่อจัดการ ดำเนินการ และดูแลรักษาระบบการชำระเงินของบริษัทฯ
ทั้งนี้ อาจมีการฟังการสนทนา และ/หรือ บันทึกสายการสนทนาทางโทรศัพท์ระหว่างบริษัทฯ
กับท่านเพื่อให้บริการ รวมทั้งฝึกอบรมพนักงานของบริษัทฯ และพัฒนาการให้บริการของบริษัทฯ</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>9)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อรู้จักท่านให้มากขึ้น</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อเรียนรู้เกี่ยวกับสินค้าและบริการที่ท่านใช้
และสินค้าและบริการอื่น ๆ ที่ท่านอาจสนใจรับให้มากยิ่งขึ้น ซึ่งรวมถึงการแจกแจงคุณลักษณะจากการประมวลผลข้อมูลส่วนบุคคลฯ
ของท่าน เช่น พิจารณาจากประเภทของสินค้าและบริการที่ท่านได้รับจากบริษัทฯ ความพึงพอใจของท่านที่จะได้รับการติดต่ออย่างไร
เป็นต้น</span><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>10)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อประสิทธิภาพในการทำงานของเว็บไซต์
แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อบริหารจัดการ
ดำเนินการ ติดตาม สังเกตุ และจัดการเว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯเพื่อช่วยอำนวยความสะดวกและสามารถทำงานได้อย่างเหมาะสม
มีประสิทธิภาพ และปลอดภัย เพื่ออำนวยความสะดวกและทำให้ประสบการณ์ของท่านในการใช้เว็บไซต์
แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯดียิ่งขึ้น
และเพื่อพัฒนาแผนผังและเนื้อหาเว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่
และแพลตฟอร์มของบริษัทฯ</span><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>11)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อจัดการระบบเทคโนโลยีสารสนเทศ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อวัตถุประสงค์ในการจัดการธุรกิจของบริษัทฯ
ซึ่งรวมถึงการดำเนินงานเทคโนโลยีสารสนเทศของบริษัทฯ การจัดการระบบการสื่อสาร
การดำเนินการและการตรวจสอบการรักษาความปลอดภัยด้านเทคโนโลยีสารสนเทศ เพื่อการจัดการธุรกิจภายในตามข้อกำหนด
นโยบาย และขั้นตอนเกี่ยวกับการกำกับดูแลกิจการภายใน และเพื่ออัพเดทฐานข้อมูลของบริษัทฯ</span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>12)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อปฏิบัติหน้าที่ตามหลักเกณฑ์และการกำกับดูแล</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:<span
lang=TH> </span></span></b><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อปฏิบัติหน้าที่ตามกฎหมาย
กระบวนการทางกฎหมาย หรือคำสั่งของเจ้าหน้าที่รัฐ ซึ่งอาจรวมถึงคำสั่งของเจ้าหน้าที่รัฐภายนอกประเทศไทย
และ/หรือ ให้ความร่วมมือกับศาล หน่วยงานกำกับดูแล เจ้าหน้าที่รัฐ และหน่วยงานที่บังคับใช้กฎหมาย
เมื่อมีเหตุอันควรเชื่อได้ว่า บริษัทฯมีหน้าที่ตามกฎหมายที่จะต้องปฏิบัติตาม และเมื่อการเปิดเผยข้อมูลส่วนบุคคลฯ
ของท่านเป็นความจำเป็นอย่างยิ่งยวดเพื่อปฏิบัติตามหน้าที่ตามกฎหมาย
กระบวนการทางกฎหมาย หรือคำสั่งของรัฐดังกล่าว ซึ่งหมายรวมถึง การออกใบกำกับภาษีหรือแบบแสดงรายการภาษี
เพื่อปฏิบัติหน้าที่ตามกฎหมายที่เกี่ยวข้องกับธุรกิจการชำระเงินอิเล็กทรอนิกส์ </span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>(e-Payment)
<span lang=TH>การเงิน เพื่อบันทึกและดูแลการติดต่อสื่อสาร เพื่อการเปิดเผยต่อเจ้าหน้าที่สรรพากร
หน่วยงานกำกับดูแลธุรกิจให้บริการทางการเงิน หรือหน่วยงานที่มีหน้าที่กำกับดูแลและหน่วยงานอื่น
ๆ ของรัฐ ตลอดจนเพื่อการสืบสวนหรือป้องกันอาชญากรรม</span><b><o:p></o:p></b></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>13)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อปกป้องประโยชน์ของบริษัทฯ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อรักษาความปลอดภัยและจริยธรรมในการดำเนินธุรกิจของบริษัทฯ
เพื่อใช้สิทธิหรือปกป้องประโยชน์ของบริษัทฯเมื่อมีความจำเป็นและกฎหมายอนุญาตให้กระทำได้
เพื่อติดตามเหตุการณ์ต่าง ๆ เพื่อป้องกันและรายงานการกระทำความผิดทางอาญาและเพื่อรักษาความปลอดภัยและจริยธรรมในธุรกิจของบริษัทฯ</span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>14)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อสืบหา ยับยั้ง
และป้องกันการฉ้อฉล/การกระทำผิดกฎหมาย</span></b><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อพิสูจน์และยืนยันตัวตนของท่าน
และเพื่อตรวจสอบการปฏิบัติตามกฎหมายและหลักเกณฑ์อื่น ๆ (เช่น
เพื่อปฏิบัติตามกฎหมายและหลักเกณฑ์ที่เกี่ยวกับธุรกิจการชำระเงินอิเล็กทรอนิกส์ </span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>(e-Payment)<span
lang=TH> การเงิน และเพื่อป้องกันการฉ้อฉลตลอดจนธุรกรรมที่ต้องสงสัย</span>) <span
lang=TH>การตรวจสอบและบันทึกภายใน การบริหารจัดการทรัพย์สิน ระบบและมาตรการควบคุมทางธุรกิจอื่น
ๆ</span><b><o:p></o:p></b></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>15)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อโอนในกรณีที่มีการควบรวมกิจการ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เมื่อมีการขาย
โอน ควบรวมกิจการ ปรับโครงสร้างองค์กร หรือเหตุการณ์ในทำนองเดียวกัน บริษัทฯ อาจมีการถ่ายโอนข้อมูลส่วนบุคคลฯ
ของท่านไปยังบุคคลอื่นใดซึ่งเป็นส่วนหนึ่งของการดำเนินธุรกรรมดังกล่าว </span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>16)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>ความเสี่ยง</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เพื่อดำเนินการบริหารจัดการความเสี่ยง
ตรวจสอบ และประเมินความเสี่ยง เพื่อตรวจสอบเครดิตและสอบทานสถานะทางการเงินของลูกค้า</span><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l2 level1 lfo3;
tab-stops:.5in'><![if !supportLists]><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-fareast-font-family:
"Cordia New";mso-fareast-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;
mso-bidi-theme-font:minor-bidi'><span style='mso-list:Ignore'>17)<span
style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>&nbsp;&nbsp;ชีวิต</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>เพื่อป้องกันหรือยับยั้งอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลใด ๆ</span><b><o:p></o:p></b></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เมื่อบริษัทฯ
มีความจำเป็นต้องเก็บข้อมูลส่วนบุคคลฯ ของท่านเพื่อการปฏิบัติหน้าที่ตามกฎหมายหรือเพื่อการเข้าทำสัญญา<span
style='letter-spacing:-.1pt'>กับ</span>ท่าน หากท่านไม่ประสงค์ให้ข้อมูลส่วนบุคคลฯ
ของท่านกับบริษัทฯ เมื่อมีการร้องขอ บริษัทฯ อาจไม่สามารถให้ (หรืออาจต้องยุติการให้)
สินค้าและบริการของบริษัทฯ แก่ท่าน</span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>3.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บุคคลที่บริษัทฯอาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลฯ
ของท่าน</span></b><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลฯ
ของท่านให้แก่บุคคลที่สามดังต่อไปนี้เพื่อการดำเนินการกับข้อมูลส่วนบุคคลฯ ตาม<span
style='letter-spacing:-.1pt'>วัตถุประสงค์</span>ภายใต้นโยบายความเป็นส่วนตัวฯ
ฉบับนี้ อนึ่ง บุคคลที่สามดังกล่าวอาจมีภูมิลำเนาอยู่ในหรือนอกราชอาณาจักรไทย ท่านสามารถอ่านนโยบายความเป็นส่วนตัวของบุคคลที่สามเหล่านี้เพื่อศึกษารายละเอียดเพิ่มเติมว่าข้อมูลส่วนบุคคลฯ
ของท่านได้ถูกดำเนินการอย่างไร</span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>3.</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>1<span
lang=TH><span style='mso-tab-count:1'>        </span>ผู้ให้บริการของบริษัทฯ</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯอาจใช้บริษัท
ตัวแทน หรือผู้รับจ้างรายอื่นเพื่อให้บริการในนามของบริษัทฯหรือเพื่อช่วยบริษัทฯในการนำส่งสินค้าและบริการให้แก่ท่าน
ดังนั้น บริษัทฯอาจแบ่งปันข้อมูลส่วนบุคคลฯ ให้แก่บุคคลดังนี้
ซึ่งรวมถึงแต่ไม่จำกัดเพียง (</span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>1<span lang=TH>) ผู้ให้บริการและพัฒนาด้านโครงสร้างพื้นฐาน
ซอฟต์แวร์ เว็บไซต์ และเทคโนโลยีสารสนเทศ (</span>2<span lang=TH>) ผู้ให้บริการทางคมนาคม
(</span>3<span lang=TH>) ผู้ให้บริการพื้นที่จัดเก็บข้อมูลและระบบคลาวด์ (</span>4<span
lang=TH>) ผู้ให้บริการ<span style='color:black;'>ตรวจสอบ แจกแจง
และวิเคราะห์ข้อมูล (</span></span><span style='color:black;'>5<span
lang=TH>) ตัวแทนหรือเอเจนซี่การตลาด การโฆษณา และการสื่อสาร (</span>6<span
lang=TH>) หน่วยงานวิจัย (</span>7<span lang=TH>) หน่วยงานสำรวจ (</span>8<span
lang=TH>) ผู้จัดแคมเปญโฆษณาและงานอีเว้นท์ (</span>9<span lang=TH>)
ผู้ให้บริการทางโทรศัพท์ <span style='mso-spacerun:yes'> </span>(</span>Call
Center<span lang=TH>) (1</span>0<span lang=TH>) ผู้ให้บริการการชำระเงิน
ระบบการชำระเงิน การพิสูจน์ตัวตน (</span>11<span lang=TH>) ผู้รับดูแลงานด้านการบริหารจัดการ
และ</span>/<span lang=TH>หรือ (</span>12<span lang=TH>) ผู้ประกอบธุรกิจด้าน</span></span><span
lang=TH>โทรคมนาคมและการสื่อสาร<span style='color:black;'> </span></span><span
style='color:black;'><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>ภายในช่วงระยะเวลาที่ให้บริการ ผู้ให้บริการดังกล่าวอาจมี</span><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>การ<span
style='color:black;'>เข้าถึงข้อมูลส่วนบุคคลฯ ของท่าน อย่างไรก็ดี
บริษัทฯจะให้ผู้ให้บริการดังกล่าวเข้าถึงข้อมูลส่วนบุคคลฯ เท่าที่จำเป็นเพื่อการให้บริการเท่านั้น
โดยบริษัทฯจะขอให้ผู้ให้บริการไม่ใช้ข้อมูลส่วนบุคคลฯ
ของท่านเพื่อวัตถุประสงค์อื่นใด ทั้งนี้ บริษัทฯ จะดูแลผู้ให้บริการทุกรายที่บริษัทฯทำงานด้วยจะรักษาข้อมูลส่วนบุคคลฯ
ของท่านอย่างปลอดภัย</span></span><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>3.2<span style='mso-tab-count:1'>        </span><span
lang=TH>คู่ค้าทางธุรกิจของบริษัทฯ</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ
อาจโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังคู่ค้าทางธุรกิจของบริษัทฯ
ซึ่งรวมถึงแต่ไม่จำกัดเพียงผู้ให้บริการการตลาดดิจิทัล
ผู้ให้บริการด้านการธนาคารและการเงิน ผู้ประกอบธุรกิจโปรแกรมสะสมแต้มและของรางวัล
ผู้ให้บริการด้านการประกันภัย ผู้ประกอบธุรกิจโทรคมนาคมและการสื่อสาร ผู้ประกอบอาชีพทางการเงิน
ผู้ให้บริการด้านการบริหารจัดการทรัพย์สิน ผู้ให้บริการด้านการลงทุน ผู้ประกอบการค้าปลีก
ผู้ประกอบการด้านพาณิชย์อิเล็กทรอนิกส์ ผู้ลงทุน คู่ค้าในโปรแกรมร่วมลงทะเบียน คู่ค้าที่บริษัทฯแนะนำซึ่งอาจร่วมกับบริษัทฯในการเสนอสินค้าหรือบริการ
คู่ค้าที่มีสมาชิกร่วม ผู้ให้บริการ<span style='color:black;mso-themecolor:text1;
'>กิจกรรมทางการตลาดแบบ </span></span><span style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;
mso-themecolor:text1;'>Lead Generation</span><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
คู่ค้าทางธุรกิจที่บริษัทฯ ให้บริการ<span style='color:black;mso-themecolor:
text1;'>กิจกรรมทางการตลาดแบบ </span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;mso-themecolor:text1;'>Lead Generation</span><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
style='color:black;'>หน่วยงานวิจัย
คู่ค้าที่สำรวจตลาดรวมถึงลูกค้าของคู่ค้านั้น</span> <span style='color:black;
'>ตัวแทนหรือเอเจนซี่สื่อโฆษณา เพื่อทำการวิจัยด้านการตลาด สำรวจ
ประเมิน พฤติกรรม สถิติ และการแบ่งส่วนตลาด แนวโน้มและรูปแบบการบริโภค
ผู้รายงานเพื่อโฆษณาแคมเปญการตลาดของบริษัทฯ</span> ผู้ประกอบอาชีพทางการเงินอิสระ
ซึ่งให้ใบเสนอราคาสำหรับสินค้าหรือบริการทางการเงิน โดยที่คู่ค้าทางธุรกิจผู้รับข้อมูลนั้นตกลงที่จะปฏิบัติต่อข้อมูลส่วนบุคคลฯ
ของท่านในลักษณะที่สอดคล้องกับนโยบายความเป็นส่วนตัวฯ ฉบับนี้ <span
style='color:black;'><span style='mso-spacerun:yes'> </span></span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;color:black;'>3.</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>3<span lang=TH><span style='mso-tab-count:1'>        </span>เว็บไซต์เครือข่ายสังคม
</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>กรณีท่านลงชื่อเข้าใช้งานโดยใช้ระบบการลงชื่อเข้าใช้งานของเว็บไซต์เครือข่ายสังคม<b>
</b>ถือว่าท่านได้ให้อำนาจโดยชัดแจ้งแก่บริษัทฯในการเข้าถึงและจัดเก็บข้อมูลที่เป็นสาธารณะในบัญชีเครือข่ายสังคมของท่าน
(เช่น </span><span lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>เฟซบุ๊ก กูเกิล อินสตาแกรม)
รวมทั้งข้อมูลอื่นที่เกิดขึ้นระหว่างการใช้<span style='color:black;'>ระบบลงชื่อเข้าใช้งานจากเครือข่ายสังคมดังกล่าว นอกจากนี้ บริษัทฯอาจมีการสื่อสารอีเมลของท่านไปยังเครือข่ายสังคม<b>
</b>เพื่อ</span>พิจารณา<span style='color:black;'>ว่าท่านเป็นผู้ใช้งานในเครือข่ายสังคมนั้นอยู่แล้วหรือไม่
และเพื่อแสดงโฆษณาบนบัญชีเครือข่ายสังคมของท่านเมื่อเหมาะสม<o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ
อาจมีความร่วมมือกับบุคคลที่สามที่อนุญาตให้ท่านลงทะเบียนเข้าใช้บริการหรือเข้าร่วมโปรโมชั่นของบุคคลดังกล่าว
ตัวอย่างเช่น บางบริษัทอนุญาตให้ท่านใช้หมายเลขโปรแกรมสะสมแต้ม หรือชื่อเข้าใช้บริการออนไลน์ของท่านเพื่อรับหรือลงทะเบียนเพื่อรับบริการของบุคคลที่สามดังกล่าว
นอกจากนี้ ผู้ให้บริการบัญชีเครือข่ายสังคมของท่านยังได้ยินยอมให้ท่านเชื่อมต่อบัญชีเครือข่ายสังคมของท่านเข้ากับบัญชีบริการออนไลน์ของท่านหรือลงชื่อเข้าใช้บัญชีบริการออนไลน์ของท่านจากบัญชีเครือข่ายสังคมของท่าน
เมื่อท่านลงทะเบียนเข้ารับบริการเหล่านั้น บริษัทฯจะเปิดเผยข้อมูลส่วนบุคคลฯ
ของท่านต่อบุคคลที่สามดังกล่าว หากท่านไม่ประสงค์ที่จะแบ่งปันข้อมูลส่วนบุคคลฯ
ของท่านในลักษณะเช่นนี้ โปรดอย่าให้หมายเลขหรือชื่อบัญชีโปรแกรมสะสมแต้มหรือรางวัลแก่บุคคลที่สาม
อย่าใช้บัญชีบริการออนไลน์เพื่อลงทะเบียนรับโปรโมชั่นของบุคคลที่สาม และอย่าเชื่อมต่อบัญชีบริการออนไลน์ของท่านกับบัญชีบริการของบุคคลที่สาม
ข้อมูลที่มีการแบ่งปันกันในลักษณะนี้จะอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลที่สาม
และไม่อยู่ภายใต้นโยบายความเป็นส่วนตัวฯ ฉบับนี้</span><span style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>3.</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>4<span
lang=TH><span style='mso-tab-count:1'>        </span>บุคคลที่สามที่ได้รับอนุญาตตามกฎหมาย</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ในบางกรณี
บริษัทฯอาจต้องเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลฯ ของท่านเพื่อปฏิบัติหน้าที่ตามกฎหมายหรือข้อกำหนด
ซึ่งหมายความรวมถึงต่อหน่วยงานที่บังคับใช้กฎหมาย ศาล ผู้กำกับดูแล
เจ้าหน้าที่ของรัฐ หรือบุคคลอื่นใดที่บริษัทฯเชื่อว่าจำเป็นเพื่อปฏิบัติหน้าที่ตามกฎหมายหรือข้อกำหนด
หรือเพื่อปกป้องสิทธิของบริษัทฯ สิทธิของบุคคลที่สาม หรือความปลอดภัยของบุคคลใด ๆ
หรือเพื่อสืบหา ป้องกัน หรือจัดการกับปัญหาการฉ้อโกง ความมั่นคง หรือความปลอดภัย </span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>3.</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>5<span
lang=TH><span style='mso-tab-count:1'>        </span>ที่ปรึกษาวิชาชีพ</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>หมายรวมถึงนักกฎหมายและผู้ตรวจสอบบัญชีซึ่งมีส่วนช่วยเหลือในการดำเนินธุรกิจและต่อสู้หรือหยิบยกข้อเรียกร้องทางกฎหมายอย่างหนึ่งอย่างใด</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>3.</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>6<span
lang=TH><span style='mso-tab-count:1'>        </span>บุคคลที่สามที่เกี่ยวข้องกับการโอนธุรกิจ</span><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ
อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังคู่ค้าทางธุรกิจ ผู้ลงทุน
ผู้ถือหุ้นที่มีนัยสำคัญ หรือผู้รับโอนของบริษัทฯ ในกรณีที่มีการปรับโครงสร้างองค์กร
การเปลี่ยนแปลงโครงสร้างบริษัท การควบรวมกิจการ การเข้าจองกิจการ การขาย การจอง
การร่วมทุน การโอน หรือเหตุการณ์อื่นใดในทำนองเดียวกันที่มีการโอนหรือการจำหน่ายไปซึ่งธุรกิจ
ทรัพย์สิน หรือหุ้นของบริษัทฯ ไม่ว่าทั้งหมดหรือแต่บางส่วน ทั้งนี้ หากเกิดเหตุการณ์หนึ่งเหตุการณ์ใดข้างต้น
ฝ่ายผู้รับข้อมูลจะปฏิบัติต่อข้อมูลส่วนบุคคลฯ ของท่านตามนโยบายความเป็นส่วนตัวฯ
ฉบับนี้ </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-pagination:
widow-orphan lines-together;page-break-after:avoid;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>4.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การถ่ายโอนข้อมูลส่วนบุคคลฯ
ระหว่างประเทศ</span></b><b><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal;mso-pagination:widow-orphan lines-together;
page-break-after:avoid'><span lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังบุคคลที่สามหรือเซิร์ฟเวอร์ที่ตั้งอยู่ในต่างประเทศ
ซึ่งประเทศปลายทางอาจมีหรือไม่มีมาตรฐานการคุ้มครองข้อมูลในระดับเดียวกัน บริษัทฯ ได้ดำเนินการตามขั้นตอนและมาตรการต่าง
ๆ เพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลฯ ของท่านได้โอนไปอย่างปลอดภัย
ฝ่ายผู้รับข้อมูลมีมาตรฐานการคุ้มครองข้อมูลที่เหมาะสมและเป็นการโอนที่ถูกต้องตามกฎหมาย
โดยอาศัยข้อยกเว้นตามกฎหมายที่อนุญาตให้ส่งหรือโอนข้อมูลได้</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-pagination:
widow-orphan lines-together;page-break-after:avoid;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>5.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลฯ
ของท่านไว้เป็นระยะเวลาเท่าใด</span></b><b><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัทฯเก็บรักษาข้อมูลส่วนบุคคลฯ
ของท่านไว้เป็นระยะเวลาเท่าที่จำเป็นตามสมควรเพื่อบรรลุวัตถุประสงค์ที่บริษัทฯได้รับข้อมูล
และเพือปฏิบัติหน้าที่ของบริษัทฯตามกฎหมายและข้อกำหนด อย่างไรก็ตาม บริษัทฯอาจจำเป็นต้องเก็บรักษาข้อมูลส่วนบุคคลฯ
ของท่านไว้เป็นระยะเวลานานกว่านั้นตามที่กฎหมายกำหนด</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>6.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>คุกกี้และการใช้คุกกี้</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
letter-spacing:-.1pt'>หากท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ บริษัทฯจะรวบรวมข้อมูลจากท่านโดยอัตโนมัติโดยใช้เครื่องมือติดตามและคุกกี้
ท(คุกกี้ คือ เทคโนโลยีติดตามที่ใช้ในการ</span><span lang=TH style='font-size:
14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>วิเคราะห์<span
style='letter-spacing:-.1pt'>แนวโน้ม บริหารจัดการเว็บไซต์ของบริษัทฯ
ติดตามความเคลื่อนไหวของผู้ใช้งานเว็บไซต์ หรือจดจำการตั้งค่าของผู้ใช้งาน
คุกกี้บางส่วนมีความจำเป็นเนื่องจากหากไม่มีคุกกี้เหล่านั้นเว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม
สำหรับคุกกี้ส่วนอื่น ๆ มีไว้เพื่อความสะดวกของผู้เยี่ยมชม เช่น คุกกี้ที่จดจำชื่อผู้ใช้งานของท่านอย่างปลอดภัย
รวมถึงภาษาที่ท่านใช้อีกด้วย)</span></span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;letter-spacing:-.1pt'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'>หากท่านปฏิเสธคุกกี้ ความสามารถของท่านในการใช้งาน</span><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>เว็บไซต์<span
style='color:black;'>ของบริษัทฯบางฟังก์ชั่นหรือบางหน้าหรือทั้งหมดอาจถูกจำกัด</span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
color:black;'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>7.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>สิทธิของท่านในฐานะเจ้าของข้อมูล</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;text-indent:.5in;line-height:normal'><span lang=TH
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ภายใต้บังคับของกฎหมายและข้อยกเว้นตามกฎหมาย
ท่านอาจมีสิทธิดังต่อไปนี้</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>1)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การเข้าถึง</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ท่านอาจมีสิทธิเข้าถึงหรือขอสำเนาข้อมูลส่วนบุคคลฯ
เกี่ยวกับท่านที่บริษัทฯดำเนินการอยู่ ทั้งนี้
เพื่อความเป็นส่วนตัวและความปลอดภัยของตัวท่านเอง บริษัทฯอาจขอให้ท่านพิสูจน์ยืนยันตัวตนก่อนให้ข้อมูลส่วนบุคคลฯ
ที่ท่านขอแก่ท่าน</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>2)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การแก้ไขให้ถูกต้อง</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>หากพบว่าข้อมูลส่วนบุคคลฯ
ของท่านที่บริษัทฯดำเนินการอยู่ ซึ่งไม่สมบูรณ์ ไม่ถูกต้อง ทำให้เข้าใจผิด หรือไม่เป็นปัจจุบัน
ท่านอาจมีสิทธิแก้ไขข้อมูลดังกล่าวให้ถูกต้อง</span><span style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>3)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การขอรับข้อมูล</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>: </span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ท่านอาจมีสิทธิในการได้รับข้อมูลส่วนบุคคลฯ
ของท่านที่บริษัทฯมีในรูปแบบอิเล็กทรอนิกส์
และสิทธิในการส่งข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น ทั้งนี้
เฉพาะ (ก) ข้อมูลส่วนบุคคลฯ ที่ท่านได้ให้ไว้กับบริษัทฯ และ (ข) บริษัทฯได้ดำเนินการกับข้อมูลดังกล่าวนั้นบนฐานความยินยอมจากท่านหรือเพื่อปฏิบัติตามสัญญาต่อท่าน</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>4)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การคัดค้าน</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>ท่านอาจมีสิทธิคัดค้านการดำเนินการบางอย่างกับข้อมูลส่วนบุคคลฯ ของท่าน
เช่น ปฏิเสธการตลาดแบบตรง</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>5)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การจำกัด</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>
ท่านอาจมีสิทธิจำกัดการดำเนินการของบริษัทฯต่อข้อมูลส่วนบุคคลฯ
ของท่านหากท่านเชื่อว่าข้อมูลดังกล่าวไม่ถูกต้อง การดำเนินการของบริษัทฯไม่ชอบด้วยกฎหมาย
หรือบริษัทฯไม่มีความจำเป็นต้องดำเนินการกับข้อมูลดังกล่าวเพื่อวัตถุประสงค์ใดวัตถุประสงค์หนึ่งอีกต่อไป</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>6)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การถอนความยินยอม</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>ท่านอาจถอนความยินยอมของท่านในเวลาใดก็ได้สำหรับวัตถุประสงค์ที่ท่านได้ให้ความยินยอมไว้กับบริษัทฯเพื่อดำเนินการกับข้อมูลส่วนบุคคลฯ
ของท่าน</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>7)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>การลบ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>:</span></b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'> <span
lang=TH>ท่านอาจมีสิทธิขอให้บริษัทฯลบหรือทำให้ข้อมูลส่วนบุคคลฯ ของท่านที่บริษัทฯดำเนินการอยู่เป็นข้อมูลที่ไม่อาจระบุตัวตนได้
เว้นแต่เป็นกรณีที่บริษัทฯไม่ได้มีหน้าที่ในการดำเนินการเช่นนั้นหากบริษัทฯต้องเก็บรักษาข้อมูลดังกล่าวไว้เพื่อปฏิบัติหน้าที่ตามกฎหมาย
หรือเพื่อการใช้สิทธิทางกฎหมายของบริษัทฯ</span><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l0 level2 lfo5'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>8)<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;letter-spacing:-.2pt'>การยื่นคำ</span></b><b><span
lang=TH style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'>ร้อง</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi;
letter-spacing:-.2pt'>:</span></b><span style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi;letter-spacing:-.2pt'> <span lang=TH>ท่านอาจมีสิทธิยื่นคำร้องต่อเจ้าหน้าที่ที่มีอำนาจ
หากท่านเชื่อว่าการดำเนินการของบริษัทฯต่อข้อมูลส่วนบุคคลฯ
ของท่านไม่ชอบด้วยกฎหมายหรือไม่ปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลที่มีผลบังคับใช้</span></span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-bottom:12.0pt;text-align:justify;
text-justify:inter-cluster;text-indent:-.5in;line-height:normal;mso-list:l3 level1 lfo1'><![if !supportLists]><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-fareast-font-family:"Cordia New";mso-fareast-theme-font:minor-bidi;
mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><span
style='mso-list:Ignore'>8.<span style='margin-left:10px'>
</span></span></span></b><![endif]><b><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>รายละเอียดการติดต่อของบริษัทฯ</span></b><b><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:12.0pt;text-align:justify;text-justify:
inter-cluster;line-height:normal'><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>หากท่านประสงค์ที่จะติดต่อบริษัทฯเพื่อใช้สิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลฯ
ของท่าน หรือหากท่านมีข้อสอบถามใด ๆ เกี่ยวกับข้อมูลส่วนบุคคลฯ ของท่านภายใต้นโยบายความเป็นส่วนตัวฯ
ฉบับนี้ กรุณาติดต่อบริษัทฯหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัทฯได้ที่</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:-.5in;line-height:normal;mso-list:l5 level2 lfo6'><![if !supportLists]><span
style='font-size:10.0pt;font-family:Sarabun;mso-fareast-font-family:Sarabun'><span
style='mso-list:Ignore'>1)<span style='margin-left:10px'>
</span></span></span><![endif]><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด
</span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:0in;line-height:normal;mso-list:l6 level1 lfo4'><![if !supportLists]><span
style='font-size:14.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
mso-bidi-font-family:Symbol'><span style='mso-list:Ignore'>·<span
style='margin-left:10px'>
</span></span></span><![endif]><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>818/27 </span><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi'>เดอะมาสเตอร์แอทบีทีเอสอุดมสุข ถนนอุดมสุข แขวงบางนาเหนือ เขตบางนา
กรุงเทพมหานคร </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>10260<o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:0in;line-height:normal;mso-list:l6 level1 lfo4'><![if !supportLists]><span
style='font-size:14.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
mso-bidi-font-family:Symbol'><span style='mso-list:Ignore'>·<span
style='margin-left:10px'>
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>contact@worldrewardsolutions.com</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:-.5in;line-height:normal;mso-list:l5 level2 lfo6'><![if !supportLists]><span
style='font-size:10.0pt;font-family:Sarabun;mso-fareast-font-family:Sarabun'><span
style='mso-list:Ignore'>2)<span style='margin-left:10px'>
</span></span></span><![endif]><span lang=TH style='font-size:14.0pt;
font-family:"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi;mso-bidi-theme-font:minor-bidi'>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:0in;line-height:normal;mso-list:l6 level1 lfo4'><![if !supportLists]><span
style='font-size:14.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
mso-bidi-font-family:Symbol'><span style='mso-list:Ignore'>·<span
style='margin-left:10px'>
</span></span></span><![endif]><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>818/27 </span><span lang=TH style='font-size:14.0pt;font-family:
"Cordia New",sans-serif;mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:
minor-bidi'>เดอะมาสเตอร์แอทบีทีเอสอุดมสุข ถนนอุดมสุข แขวงบางนาเหนือ เขตบางนา
กรุงเทพมหานคร </span><span style='font-size:14.0pt;font-family:"Cordia New",sans-serif;
mso-ascii-theme-font:minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:
minor-bidi'>10260<o:p></o:p></span></p>

<p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:
12.0pt;margin-left:1.0in;text-align:justify;text-justify:inter-cluster;
text-indent:0in;line-height:normal;mso-list:l6 level1 lfo4'><![if !supportLists]><span
style='font-size:14.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
mso-bidi-font-family:Symbol'><span style='mso-list:Ignore'>·<span
style='margin-left:10px'>
</span></span></span><![endif]><span style='mso-bidi-font-size:11.0pt;
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>dpo@worldrewardsolutions.com</span><span
style='font-size:14.0pt;font-family:"Cordia New",sans-serif;mso-ascii-theme-font:
minor-bidi;mso-hansi-theme-font:minor-bidi;mso-bidi-theme-font:minor-bidi'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-align:justify;text-justify:inter-cluster;line-height:
normal'><span style='font-size:10.0pt;font-family:Sarabun'><o:p></o:p></span></p>

<p class=MsoNormal align=center style='margin-bottom:12.0pt;text-align:center;
line-height:normal'><span style='font-size:10.0pt;font-family:Sarabun'>***********************************<o:p></o:p></span></p>

</div>

</body>

</html>
`)}
          {/* <h5 className="modal-title w-100 mb-4"  >
            ประกาศ 
          </h5>
              <div>
                ตามที่ได้มีการประกาศสถานการณ์ฉุกเฉิน 
              เนื่องด้วยสถานการณ์ไวรัส <strong> Covid-19 </strong> ในประเทศขณะนี้ 
              เพื่อเพิ่มประสิทธิภาพในการควบคุมการระบาด และลดการสัญจร 
              จึงได้มีการประกาศข้อกำหนด ห้ามบุคคลออกนอกเคหะสถาน ตั้งแต่เวลา <strong> 22.00 น.- 04.00 น. </strong> 
              โดยมีผลบังคับตั้งแต่วันที่ 3 เมษายน 2563 เป็นต้นไป 
              </div>
              <br/>
              <div>
              ทั้งนี้ทาง <strong> Silver Voyage Limousine </strong>  
              จึงขอแจ้งปรับเวลาการให้บริการรถรับ-ส่งสนามบิน โดยจะให้บริการตั้งแต่เวลา <strong> 07.00 - 20.00 น.  </strong> 
              ตั้งแต่วันที่  <strong> 3 เมษายน 2563 เป็นต้นไป </strong>   จนกว่ามีการเปลี่ยนแปลง 
              </div>
              <br/>
              <div>
              สำหรับลูกค้าท่านใดที่มีการสำรองรถเข้ามาก่อนหน้านี้
              </div>
              <div>
              ทางบริษัทฯ ขอนุญาตติดต่อกลับเพื่อเรียนแจ้งข้อมูลให้กับลูกค้าต่อไป
              </div>
            
              <br/> 
              <div>
              จึงเรียนมาเพื่อทราบ
            </div> */}
        </div>
        <div className="modal-footer text-center d-block pt-0 my-4">
          <button
            type="button"
            className="btn btn-success"
            style={{width:'45%'}}
            onClick={() => {
              props.closePopup()
            }}
          >
            ACCEPT
          </button>
          {" "}
          <button
            type="button"
            className="btn btn-danger"
            style={{width:`45%`}}
            onClick={() => {
              props.lockAppPopup()
            }}
          >
            DECLINE
          </button>
        </div>
      </div>
  </div>

  
      </div>
    </div>
  </div>
  )
}

export default PopupNotice

