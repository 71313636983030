import {
  POPUP_STAGE_HIDDEN,
  POPUP_STAGE_LOADING,
  POPUP_STAGE_CONSENT,
  POPUP_STAGE_SUCCESS,
  POPUP_STAGE_NOTCONSENT,
  POPUP_STAGE_ERROR,
  POPUP_STAGE_LOCK,
} from './types'

export const popupHidden = () => {
  return {
    type: POPUP_STAGE_HIDDEN,
  }
}

export const popupLoading = () => {
  return {
    type: POPUP_STAGE_LOADING,
  }
}

export const popupConsent = () => {
  return {
    type: POPUP_STAGE_CONSENT,
  }
}

export const popupSuccess = () => {
  return {
    type: POPUP_STAGE_SUCCESS,
  }
}

export const popupNotConsent = () => {
  return {
    type: POPUP_STAGE_NOTCONSENT,
  }
}

export const popupError = msg => {
  return {
    type: POPUP_STAGE_ERROR,
    payload: { msg },
  }
}

export const lockAppPopup = msg => {
  return {
    type: POPUP_STAGE_LOCK,
    payload: { msg },
  }
}

