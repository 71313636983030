import { CODE_GET_DATA, CODE_SET_DATA, CODE_POST_MONDAY, CODE_SET_PIC, CODE_REQUEST_CODE , CODE_POST_BOOKING_CORE ,CODE_SET_VALID,CODE_GET_VALID , CODE_POST_REDEEM_CORE} from './types'

export const getCouponData = (id, code) => {
  return {
    type: CODE_GET_DATA,
    payload: { id, code },
  }
}

export const setTicketData = id => {
  return {
    type: CODE_SET_DATA,
    payload: { id },
  }
}

export const setPicData = id => {
  return {
    type: CODE_SET_PIC,
    payload: { id },
  }
}

export const submitBooking = (id, code, shopCode) => {
  return {
    type: CODE_REQUEST_CODE,
    payload: { id, code, shopCode },
  }
}

export const submitBookingMonday = (code, data) => {
  return {
    type: CODE_POST_MONDAY,
    payload: { code, data },
  }
}

export const SetCodeValid = (data) => {
  return {
    type: CODE_SET_VALID,
    payload: { data },
  }
}


export const GetCodeValid = (CouponID, Code ) => {
  return {
    type: CODE_GET_VALID,
    payload: { CouponID, Code  },
  }
}

export const submitToCore = ( code, data ,privilege_id , coupon_id  ) => {
  return {
    type: CODE_POST_REDEEM_CORE,
    payload: { code, data ,privilege_id , coupon_id  },
  }
}


export const updateBookingCore = ( ...booking ) => {
  return {
    type: CODE_POST_BOOKING_CORE,
    payload: { booking },
  }
}

